<template>
  <p class="input_error m-0 text-left pl-2 p-1"><b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> {{ message }}</p>
</template>

<script>
export default {
  name: "ErrorText",
  props : ['message']
}
</script>

<style scoped>

.input_error{
  padding-left:10px;
  color : #e52528;

}


</style>
